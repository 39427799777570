import * as React from 'react';
import { cn } from '@aether/ui';
import { Slot } from '@radix-ui/react-slot';
import { Link, useLocation, useSearchParams } from 'react-router-dom';

type LinkProps = Parameters<typeof Link>[0];

export type AuthLinkProps =
  | ({ asChild?: false } & LinkProps)
  | ({ asChild: true } & Omit<React.ComponentProps<'a'>, 'ref'>);

export const AuthLink = React.forwardRef<HTMLAnchorElement, AuthLinkProps>(function AuthLink(props, ref) {
  const { asChild, ...rest } = props;
  const location = useLocation();
  const [params] = useSearchParams();

  // Add redirectTo to query params if doesn't exist already so we can redirect back to the current page after auth
  let uri =
    'to' in props
      ? typeof props.to === 'string'
        ? props.to
        : `${props.to.pathname}${props.to.search}${props.to.hash}`
      : false;
  if (uri) {
    const linkUrl = new URL(uri, window.location.href);
    const redirectTo =
      linkUrl.searchParams.get('redirectTo') ??
      params.get('redirectTo') ??
      location.pathname + location.search + location.hash;

    linkUrl.searchParams.set('redirectTo', redirectTo);
    uri = linkUrl.toString();
  }

  return asChild ? (
    <Slot ref={ref} {...rest} className={cn('font-medium text-black underline underline-offset-4', props.className)} />
  ) : (
    <Link ref={ref} {...(rest as LinkProps)} to={uri || props.to} />
  );
});
