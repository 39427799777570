import React from 'react';
import { cn, Icon, Tooltip } from '@aether/ui';
import arabesqueAi from '@aether/ui/app-shared/assets/arabesque-ai.svg';
import { getWebsiteOrigin } from '@aether/utils/services/linking-service';
import { Link, useLocation, useParams } from 'react-router-dom';

import { useAuth } from '@/utils/auth';

import { OmniSearch } from '../OmniSearch/OmniSearch';
import { RequiresAuth } from '../RequiresAuth/RequiresAuth';

export default function SideNavBar({ isOpen, handleToggle }: { isOpen: boolean; handleToggle: () => void }) {
  const { user, isAuthenticated } = useAuth();
  const scrollRef = React.useRef<HTMLDivElement>(null);

  // Check if there might be a scrollbar, if so, increase the width of the sidebar (won't be triggered on resize but that's ok)
  const isScrollable = Number(scrollRef?.current?.scrollHeight) > Number(scrollRef?.current?.offsetHeight);

  return (
    <nav
      className="sticky top-0 z-50 h-screen overflow-x-hidden"
      style={{
        width: isOpen ? `${isScrollable ? 300 : 280}px` : `${isScrollable ? 80 : 70}px`,
        transition: 'width 0.3s ease-in-out',
      }}
    >
      <div
        ref={scrollRef}
        className="flex h-full flex-col gap-10 overflow-y-auto overflow-x-hidden bg-grey-900 px-4 py-6 text-gray-200"
      >
        <Link to={isAuthenticated ? '/welcome' : getWebsiteOrigin() || '/'} className="w-full text-nowrap">
          <img src="/images/dragon.svg" alt="Arabesque AI" className="m-auto mr-2 inline-block w-[33px] align-middle" />
          <div
            style={{
              opacity: isOpen ? 1 : 0,
              transition: 'opacity 0.3s ease-in-out',
            }}
            className="inline-block align-middle"
          >
            <div className="mr-2 whitespace-nowrap text-[18px] font-semibold leading-[22px] text-gray-200">
              Portfolio Dragon
            </div>
            <div className="flex items-center gap-1 whitespace-nowrap text-[10px] leading-3 text-gray-400">
              <span>powered by</span> <img src={arabesqueAi} alt="arabesque.ai" className="inline-block w-[86px]" />
            </div>
          </div>
        </Link>
        <OmniSearch hasKeyboardOpenShortcut />
        <div>
          <NavLink to="/dashboard/all" icon="ic:outline-reorder" title="Dashboard" isOpen={isOpen} />
          <NavLink
            to="/strategy/upload-holdings"
            icon="ic:outline-upload-file"
            title="Upload new portfolio"
            isOpen={isOpen}
          />
          <NavLinkRequiresAuth
            afterAuth={{
              action: 'CreateStrategy',
              navigate: '/strategy/create',
            }}
            to="/strategy/create"
            icon="ic:outline-build"
            title="Build new portfolio"
            isOpen={isOpen}
          />
        </div>

        <div>
          <NavLinkHeader isOpen={isOpen} title="Features" />
          <PortfolioNavLink icon="ic:outline-insights" title="Analytics" subPath="/analytics" isOpen={isOpen} />
          <PortfolioNavLink icon="ic:outline-line-style" title="Attribution" subPath="/attribution" isOpen={isOpen} />
          <PortfolioNavLink icon="ic:outline-co2" title="Sustainability" subPath="/sustainability" isOpen={isOpen} />
          <PortfolioNavLink
            icon="ic:outline-balance"
            title="AI rebalance"
            subPath="/rebalance/holdings"
            isOpen={isOpen}
          />
          <PortfolioNavLink
            soon
            icon="ic:outline-tune"
            title="Configuration"
            subPath="/configuration"
            isOpen={isOpen}
          />
          <PortfolioNavLink
            icon="material-symbols:file-save-outline"
            title="Factsheet"
            subPath="/factsheet"
            isOpen={isOpen}
          />
        </div>
        <div>
          <NavLinkHeader isOpen={isOpen} title="Settings" />
          <NavLink soon icon="material-symbols:data-table-outline" title="Data management" isOpen={isOpen} />
          <NavLink soon icon="ic:outline-tune" title="Custom factsheets" isOpen={isOpen} />
        </div>
        {/*
        TODO: delete once we are sure we don't want any golden section anymore, keeping it for the color examples in the meantime
        <div>
          <NavLinkHeader isOpen={isOpen} title="Premium Features" className="text-gold-dark-11" />
          <NavLinkRequiresAuth
            afterAuth={{
              action: 'CompareStrategies',
              navigate: '/strategies/compare/financial',
            }}
            to="/strategies/compare/financial"
            icon="ic:outline-swap-horiz"
            title="Compare portfolios"
            isOpen={isOpen}
            iconClassName="text-gold-dark-9 group-hover:text-gold-dark-11"
            className="text-gold-dark-11 hover:bg-gold-dark-4 hover:text-gold-dark-11"
          />
          <NavLink
            soon
            to="/"
            icon="material-symbols:data-table-outline"
            title="Data management"
            isOpen={isOpen}
            iconClassName="text-gold-dark-9 group-hover:text-gold-dark-11"
            className="text-gold-dark-11 hover:bg-gold-dark-4 hover:text-gold-dark-11"
          />
          <NavLink
            soon
            to="/"
            icon="ic:outline-tune"
            title="Custom factsheets"
            isOpen={isOpen}
            iconClassName="text-gold-dark-9 group-hover:text-gold-dark-11"
            className="text-gold-dark-11 hover:bg-gold-dark-4 hover:text-gold-dark-11"
          />
        </div> */}
        <div className="mt-auto space-y-10">
          {isAuthenticated && (
            <div>
              <NavLinkHeader isOpen={isOpen} title="Account" />
              <div
                className="-mt-2 mb-2 overflow-hidden text-ellipsis text-xs text-gray-200"
                style={{
                  opacity: isOpen ? 1 : 0,
                  transition: 'opacity 0.6s ease-in-out',
                }}
              >
                {user?.email}
              </div>
              <NavLink to="/account/edit-profile" icon="ic:outline-person" title="My profile" isOpen={isOpen} />
              <NavLink to="/account/plan" icon="ic:outline-credit-card" title="Plan" isOpen={isOpen} />
              <NavLink
                to="/auth/logout"
                icon="ic:outline-logout"
                title="Log out"
                isOpen={isOpen}
                data-testid="ac-logout"
              />
            </div>
          )}
          <NavLink
            onClick={handleToggle}
            isOpen={isOpen}
            title={isOpen ? 'Collapse' : 'Expand'}
            icon={isOpen ? 'iconamoon:arrow-left-2-light' : 'iconamoon:arrow-right-2-light'}
            iconClassName="m-0 mr-2 h-[32px] min-h-[32px] w-[34px] min-w-[34px] rounded-md bg-portfolio-500 p-1 text-white transition-colors duration-500 group-hover:bg-white group-hover:text-gray-700"
          />
        </div>
      </div>
    </nav>
  );
}

const NavLinkHeader = ({ isOpen, title, className }: { isOpen: boolean; title: string; className?: string }) => {
  return (
    <div
      style={{
        opacity: isOpen ? 1 : 0,
        transition: 'opacity 0.6s ease-in-out',
      }}
      className={cn('mb-2 overflow-hidden whitespace-nowrap text-sm font-medium uppercase leading-5', className)}
    >
      {title}
    </div>
  );
};

const NavLink = React.forwardRef<
  HTMLButtonElement,
  {
    onClick?: () => void;
    to?: string;
    title: string;
    icon: string;
    isOpen?: boolean;
    target?: string;
    className?: string;
    iconClassName?: string;
    soon?: boolean;
    disabled?: boolean;
  }
>(function NavLink(
  { title, icon, isOpen = false, target, className, iconClassName, soon, disabled, ...rest },
  forwardedRef
) {
  const { pathname } = useLocation();
  const Comp = !soon && !disabled && rest.to ? Link : 'button';
  const pathToCompare = new URL(rest.to || '/', window.location.origin).pathname;
  return (
    <Tooltip
      hidden={isOpen}
      content={
        <>
          {title}
          {soon && ' - Coming soon'}
        </>
      }
      sideOffset={22}
      className="border-0"
      asChild
      side="right"
    >
      <Comp
        // @ts-expect-error not worth fixing
        ref={forwardedRef}
        className={cn(
          'focus-visible:ring-ring group relative flex h-8 w-full items-center justify-start whitespace-nowrap rounded-md text-sm hover:bg-gray-700 hover:text-grey-100',
          rest.to && pathname.includes(pathToCompare) && 'bg-gray-700 text-gray-100',
          className
        )}
        target={target}
        {...rest}
      >
        <Icon
          className={cn(
            'mx-1.5 inline-block h-[20px] min-h-[20px] w-[20px] min-w-[20px] text-gray-400 group-hover:text-white',
            iconClassName
          )}
          icon={icon}
        />
        <span
          style={{
            opacity: isOpen ? 1 : 0,
            transition: 'opacity 0.6s ease-in-out',
          }}
          className="inline-block text-sm font-medium"
        >
          {title}
          {soon && (
            <span className="absolute inset-0 left-8 py-1.5 text-left opacity-0 backdrop-blur-md transition-all duration-200 group-hover:opacity-100">
              Coming soon
            </span>
          )}
        </span>
      </Comp>
    </Tooltip>
  );
});

function NavLinkRequiresAuth(
  props: Omit<React.ComponentProps<typeof RequiresAuth>, 'children'> & React.ComponentProps<typeof NavLink>
) {
  const { afterAuth, ...rest } = props;
  const { isAuthenticated } = useAuth();
  return (
    <RequiresAuth afterAuth={afterAuth}>
      <NavLink {...rest} disabled={!isAuthenticated} />
    </RequiresAuth>
  );
}

function PortfolioNavLink(props: {
  subPath: string;
  icon: string;
  title: string;
  isOpen?: boolean;
  soon?: boolean;
  disabled?: boolean;
}) {
  const { subPath, ...rest } = props;
  const { search } = useLocation();
  const { fundId, portfolioId: strategyId, enhancedId } = useParams();
  const basePath = portfolioBasePath({ fundId, strategyId, enhancedId });
  if (basePath) {
    return <NavLink {...rest} to={`${basePath}${subPath}${search}`} />;
  }
  return (
    <OmniSearch itemUrl={data => `${data.url || ''}${subPath}${search}`}>
      <NavLink {...rest} />
    </OmniSearch>
  );
}

function portfolioBasePath({
  strategyId,
  fundId,
  enhancedId,
}: {
  fundId?: string;
  strategyId?: string;
  enhancedId?: string;
}) {
  return strategyId
    ? `/strategies/${strategyId}`
    : fundId
      ? `/funds/${fundId}`
      : enhancedId
        ? `/ai-enhanced/${enhancedId}`
        : null;
}
